<template>
<div class="footer-box" @click="hideErweiCode">
			<a target="_blank" href="https://m.weibo.cn/u/5602102157" class="footer-list">
				<div class="top-logo">
					<img src="https://cdnzui.lenovomm.cn/web/zui14_m/static/img/foot-logo1.png?kjldjkd" alt="">
				</div>
				<p class="p1">新浪微博</p>
			</a>
			<div class="footer-list" @click.stop="showErweiCode">
				<div class="top-logo">
					<img src="https://cdnzui.lenovomm.cn/web/zui14_m/static/img/foot-logo2.png?kjldjkd" alt="">
				</div>
				<p class="p1">官方微信</p>
				<div class="erwei-code" v-show="erweicodeStatus">
					<img src="../assets/img/erwei-code.png" alt="">
				</div>
			</div>
			<a target="_blank" href="https://m.zui.com/cloudservice" class="footer-list">
				<div class="top-logo">
					<img src="https://cdnzui.lenovomm.cn/web/zui14_m/static/img/foot-logo3.png?kjldjkd" alt="">
				</div>
				<p class="p1">云服务</p>
			</a>
			<a target="_blank" href="https://m.zui.com/iunlock" class="footer-list">
				<div class="top-logo">
					<img src="https://cdnzui.lenovomm.cn/web/zui14_m/static/img/foot-logo4.png?kjldjkd" alt="">
				</div>
				<p class="p1">解锁</p>
			</a>
			<a target="_blank" href="https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip" class="footer-list">
				<div class="top-logo">
					<img src="https://cdnzui.lenovomm.cn/web/zui14_m/static/img/foot-logo5.png?kjldjkd" alt="">
				</div>
				<p class="p1">开发者计划</p>
			</a>
		</div>
</template>
<script>
export default {
    data(){
		return{
			erweicodeStatus:false
		}
	},
	methods:{
		showErweiCode(){
			this.erweicodeStatus=true
		},
		hideErweiCode(){
			this.erweicodeStatus=false
		}
	}
};
</script>
<style scoped>
.footer-box{
	width: 100%;
	height: 2rem;
	box-sizing: border-box;
	padding-top: .6rem;
	background: #FFFFFF;
	/* overflow: hidden; */
}
.footer-box .footer-list{
	display: block;
	float: left;
	width: 20%;
	text-align: center;
	position: relative;
}
.footer-box .footer-list .erwei-code{
	position: absolute;
    top: -3.5rem;
    left: -0.8rem;
	width: 3rem;
	/* display: none; */
}
.footer-box .footer-list .erwei-code img{
	display: block;
	width: 3rem !important;
}
.footer-box .footer-list .p1{
	font-family: FZLanTingHei-DB-GBK;
	font-weight: 400;
	color: #B1B1B1;
	font-size: .18rem;
	/* margin-top: .16rem; */
}
.footer-box .footer-list .top-logo{
	height:.45rem;
}

.footer-box .footer-list:nth-of-type(1) img{
	width: .37rem;
	margin: 0 auto;
}

.footer-box .footer-list:nth-of-type(2) img{
	width: .36rem;
	margin: 0 auto;
}
.footer-box .footer-list:nth-of-type(3) img{
	width: .37rem;
	margin: 0 auto;
}

.footer-box .footer-list:nth-of-type(3) img{
	width: .26rem;
	margin: 0 auto;
}

.footer-box .footer-list:nth-of-type(4) img{
	width: .39rem;
	margin: 0 auto;
}

</style>
