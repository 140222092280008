<template>
  <div class="home">
    <Header></Header>
     <div class="main-box">
			<div class="product01-box">
				<img class="img1" v-if="bannerdata.length>0" :src="bannerdata[0].coverPicture" alt="">
        <div class="select-box">

						<div class="left-img">
							<img src="../assets/img/select-btn.png" alt="">
						</div>
						<div class="right-input">
							<input type="text" v-model="searchText" @change="selectFn" placeholder="搜索" class="input">
						</div>
					</div>
			</div>
			<div class="product02-box">
				<div class="product02-content">
					
					<div class="content-box" v-if="newMsg.length>0">
						<div class="product02-slide" v-for="(item,index) in newMsg" :key="index" @click="goDetails(item.id)">
							<div class="top-time">
								<div class="top-time-logo">
									<img src="../assets/img/time-logo.png" alt="">
								</div>
								<p class="text Gotham-Book">{{timestampToTime(item.metadataInfo.CUSTOMER_PORTAL_PUBLISH_TIME)}}</p>
							</div>
							<div class="top-img">
								<img :src="item.coverPicture" alt="">
							</div>
							<div class="footer-text">
								<p class="txt-p Gotham-Book lantingheijian">{{item.name}}</p>
							</div>
						</div>
					</div>
          <div class="content-box" v-else-if="newMsg.length<1&&!newMsgStatus">
            <img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
          </div>
          <div class="content-box" v-else>
            <img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
          </div>
					
				</div>
				
			</div>
			<div :class="[updateRecords.records&&updateRecords.records.length<5?updateRecords.records.length<3?'product03-box product03-box-min1':'product03-box product03-box-min2':'product03-box']">
				<div class="top-tit">
					<p class="p1 lantinghei">更新信息</p>
					<p class="p2 Gotham-Book lantingheijian">了解ZUI往期更新记录</p>
				</div>
				<div class="nav-table">
					<div :class="[index==undateIndex?'nav-slide nav-slide-active':'nav-slide']" v-for="(item,index) in updateProductNavList" :key="index" >
						<div class="top-hover-box" @click="navSlide(index,item)">
							<div class="top-img">
								<img v-if="item.name=='YOGA'||item.value=='20220816000004'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo1.png" alt="">
								<img v-if="item.name=='YOGA'||item.value=='20220816000004'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo1-2.png" alt="">
                <img v-if="item.name=='小新'||item.value=='20220816000006'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo2.png" alt="">
								<img v-if="item.name=='小新'||item.value=='20220816000006'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo2-2.png" alt="">
                <img v-if="item.name=='拯救者'||item.value=='20220816000005'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo3.png" alt="">
								<img v-if="item.name=='拯救者'||item.value=='20220816000005'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo3-2.png" alt="">
                <!-- <img class="img1" style="width:.82rem;" src="../assets/img/product03-logo4.png" alt="">
								<img class="img2" style="width:.82rem;" src="../assets/img/product03-logo4-2.png" alt=""> -->
							</div>
							<p class="top-txt lantinghei Gotham-Book">{{item.name}}</p>
							<div class="bottom-line"></div>
							<div class="bottom-line-active"></div>
						</div>
						<div class="two-nav-box">
							<div :class="[twoUndateIndex==0?'two-slide two-slide-active Gotham-Book lantingheijian':'two-slide Gotham-Book lantingheijian']" @click="navSlide(index,item)">全部</div>
							<div :class="[twoUndateIndex==index2+1?'two-slide two-slide-active Gotham-Book lantingheijian':'two-slide Gotham-Book lantingheijian']" class="two-slide" v-for="(item2,index2) in item.childList" @click.stop="twoNavSlide(index2,item2)" :key="index2">{{item2.name}}</div>
						</div>
					
					</div>

				</div>
        <div class="three-nav-box">
							<div class="three-nav-rel" v-if="updateRecords.records&&updateRecords.records.length>0">
								<div class="product03-slide" v-for="(item,index) in updateRecords.records" :key="index" @click.stop="goYearsUpdate(item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].value)">
									<div class="top-img">
										<img :src="item.coverPicture" alt="">
									</div>
									<div class="footer-text">
										<p class="txt-p Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</p>
									</div>
								</div>
								<div class="bottom-btn" @click="nextFn(updateRecords.totalPage)"  v-if="updatePageNum<updateRecords.totalPage&&updateRecords.totalPage>1">
									<div class="bottom-btn-rel">
										<p class="see-more">
                      <span class="lantinghei Gotham-Book">查看更多</span>
                      <span class="see-more-logo">
                        <img src="../assets/img/see-more-logo.png" alt="">
                      </span>
                    </p>
									</div>
								</div>
							</div>
              	<div class="three-nav-rel" v-else-if="(!updateRecords.records||updateRecords.records.length<1)&&!updateRecordsStatus">
							    <p class="no-msg-txt Gotham-Book lantingheijian">
                    	<img class="no-msg" src="../assets/img/loading.png" alt="">
                  </p>
							</div>
							<div class="three-nav-rel" v-else>
							    <p class="no-msg-txt Gotham-Book lantingheijian">
                    	<img class="no-msg" src="../assets/img/zanwushuju.png" alt="">
                  </p>
							</div>
						</div>
			</div>
			<div class="product04-box">
				<img v-if="updatedata.length>0" :src="updatedata[0].coverPicture" alt="">
			</div>
			<div class="product05-box">
				<!-- <p class="big-p lantinghei">目前正在内测机型</p> -->
				<div class="product05-content" v-if="testModel.length>0">
					<div class="product05-slide" v-for="(item,index) in testModel" :key="index">
						<div class="top-img">
							<img :src="item.coverPicture" alt="">
						</div>
						<div class="footer-text">
							<p class="txt-p Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</p>
						</div>
					</div>
          <div class="bottom-btn" @click="nextTestFn(allTestModel.totalPage)"  v-if="testPageNum<allTestModel.totalPage&&allTestModel.totalPage>1">
            <div class="bottom-btn-rel">
              <p class="see-more">
                <span class="lantinghei Gotham-Book">查看更多</span>
                <span class="see-more-logo">
                  <img src="../assets/img/see-more-logo.png" alt="">
                </span>
              </p>
            </div>
          </div>
				</div>
        <div class="product05-content" v-else-if="testModel.length<1&&!testModelStatuas">
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
        <div class="product05-content" v-else>
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
			</div>
			<div class="product06-box">
				<img src="../assets/img/product06-img1.png" alt="">
        <div class="weixincode">
          <img :src="weixincode" alt="">
        </div>
        <div class="qqcode">
          <img :src="qqcode" alt="">
        </div>
			</div>
      <Footer></Footer>
		</div>
  </div>
</template>

<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情


    362 更新信息>机型图


    
    334 更新记录>内容详情（待确认）

    333 内测机型Banner

    366 内侧机型>机型列表

    363 二维码-微信

    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios';
import Header from "../components/Header"
import Footer from "../components/Footer"
import instance from "../utils/http";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      //搜索信息
      searchText:'',
      //首页banner
      bannerdata:[
        {coverPicture:''}
      ],
      //更新信息当前显示
      undateIndex:0,
      //更新二级信息当前显示
      twoUndateIndex:0,
      //最新消息列表
      newMsg:[],
      newMsgStatus:false,
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
      //机型图
      modelImg:[],
      //更新信息
      updateRecords:[],
      updateRecordsStatus:false,
      updatePageNum:1,
      updatePageSize:4,
      weixincode:"",
      qqcode:"",
      //内测机型信息
      testModel:[],
      testModelStatuas:false,
      //内测机型所有信息
      allTestModel:{},
      //内测机型分页
      testPageNum:1,
      testPageSize:4,

      selectArray:[],
      updateProductNavList:[],
      undateLineList:[
    
    {
        "id": "13014",
        "parent_id": "-1",
        "title": "小新",
        "value": "20220816000006"
    },
    {
        "id": "13015",
        "parent_id": "-1",
        "title": "拯救者",
        "value": "20220816000005"
    },
    {
        "id": "13013",
        "parent_id": "-1",
        "title": "YOGA",
        "value": "20220816000004"
    },
    {
        "id": "13017",
        "parent_id": "13013",
        "title": "Pad Pro 系列",
        "value": "20220816000002"
    },
    {
        "id": "13018",
        "parent_id": "13017",
        "title": "YOGA Pad Pro",
        "value": "1660533045397"
    },
    {
        "id": "13019",
        "parent_id": "13014",
        "title": "Pad 系列",
        "value": "20220816000001"
    },
    {
        "id": "13020",
        "parent_id": "13014",
        "title": "Pad Pro 系列",
        "value": "20220816000009"
    },
    {
        "id": "13021",
        "parent_id": "13014",
        "title": "Pad Plus 系列",
        "value": "20220816000003"
    },
    {
        "id": "13022",
        "parent_id": "13019",
        "title": "小新 Pad 2022",
        "value": "1660532990839"
    },
    {
        "id": "13023",
        "parent_id": "13019",
        "title": "小新 Pad 2020",
        "value": "1660532995628"
    },
    {
        "id": "13024",
        "parent_id": "13020",
        "title": "小新 Pad Pro",
        "value": "1660532999726"
    },
    {
        "id": "13025",
        "parent_id": "13020",
        "title": "小新 Pad Pro 2021",
        "value": "1660533005730"
    },
    {
        "id": "13026",
        "parent_id": "13020",
        "title": "小新 Pad Pro 12.6",
        "value": "1660533030393"
    },
    {
        "id": "13027",
        "parent_id": "13020",
        "title": "小新 Pad Pro 2022",
        "value": "1660533033319"
    },
    {
        "id": "13028",
        "parent_id": "13021",
        "title": "小新 Pad Plus",
        "value": "1660533037850"
    },
    {
        "id": "13029",
        "parent_id": "13021",
        "title": "小新 Pad Plus 5G",
        "value": "1660533041608"
    },
    {
        "id": "13030",
        "parent_id": "13015",
        "title": "电竞手机",
        "value": "20220816000008"
    },
    {
        "id": "13031",
        "parent_id": "13015",
        "title": "电竞平板",
        "value": "20220816000007"
    },
    {
        "id": "13032",
        "parent_id": "13030",
        "title": "拯救者电竞手机 Y90",
        "value": "1660532872160"
    },
    {
        "id": "13033",
        "parent_id": "13030",
        "title": "拯救者电竞手机 Y70",
        "value": "1660532970634"
    },
    {
        "id": "13034",
        "parent_id": "13030",
        "title": "拯救者电竞手机一代",
        "value": "1660532975902"
    },
    {
        "id": "13035",
        "parent_id": "13030",
        "title": "拯救者电竞手机 2Pro",
        "value": "1660532980832"
    },
    {
        "id": "13036",
        "parent_id": "13031",
        "title": "拯救者电竞平板 Y700",
        "value": "1660532986406"
    }
]
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    console.log(this.undateLineList[0].id)
    //设置整体展厅参数
     var getSelectArray=this.dataFilter('parent_id',this.undateLineList[0].id,this.undateLineList);
      var selectArray=[];
      selectArray.push(this.undateLineList[0].value);
      for(var i=0;i<getSelectArray.length;i++){
        selectArray.push(getSelectArray[i].value)
      }
      console.log(getSelectArray)
      // this.cacheKey = query.cacheKey;
      this.getUpdateMsg(selectArray)
      // this.navSlide(0,this.undateLineList[0].value,this.undateLineList[0].id)
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
    //获取首页banner
    var indexBanner={
      "blockId": interfaces.blockIdArr.indexBanner,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    }
   $this.searchFn(indexBanner)
    var newsMssdata={
      "blockId": interfaces.blockIdArr.newMsg,
      "pageNum":1,
      "pageSize":8,
      "searchText":"",
      "filterConditionList":[]
    }
    $this.searchFn(newsMssdata)
    //获取首页banner
    var updateBanner={
      "blockId": interfaces.blockIdArr.neicebanner,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    }
   $this.searchFn(updateBanner)
    //获取首页banner
    var newsjixing={
      "blockId": interfaces.blockIdArr.newsjixing,
      "pageNum":1,
      "pageSize":20,
      "searchText":"",
      "filterConditionList":[]
    }
    $this.searchFn(newsjixing) 


    $this.searchFn({
      "blockId": interfaces.blockIdArr.update,
      "pageNum":this.updatePageNum,
      "pageSize":this.updatePageSize,
      "searchText":"",
      "filterConditionList":[]
    })


     //获取微信二维码
     $this.searchFn({
      "blockId": interfaces.blockIdArr.vxcode,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    })
    //获取QQ二维码
     $this.searchFn({
      "blockId": interfaces.blockIdArr.wbcode,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    })
    //获取内测机型
     $this.searchFn({
      "blockId": interfaces.blockIdArr.neicejixing,
      "pageNum":this.testPageNum,
      "pageSize":this.testPageSize,
      "searchText":"",
      "filterConditionList":[]
    })
    // $this.getBaseInfo(334)
    $this.getBaseInfo332(interfaces.blockIdArr.newsjixing)
  },
  //事件
  methods: {
    getBaseInfo332(id){
      var $this = this;
			var data = {
				token: "",
				param: {
				blockId:id,
				},
			};
			instance
				.post(`/open-api/standard/v1/getPublishSetting`, data, {
				headers: {
					"Content-Type": "application/json",
					"Token-type": "bearer",
				},
				})
				.then((res) => {
				
				if(res&&res.data.result){
					// 获取初始化数据
					console.log(res.data.result[0].childList)
          this.updateProductNavList=res.data.result[0].childList.filter(item=>{
            return item.name!="无"
          })
          console.log(this.updateProductNavList)
          this.navSlide(0,this.updateProductNavList[0])
				}else{
					
						
				}
				
				});
    },
    //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            return error
          });
    },
     //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                if(data.blockId==interfaces.blockIdArr.indexBanner){
                  $this.bannerdata=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.newMsg){
                  $this.newMsgStatus=true
                  $this.newMsg=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.neicebanner){
                  $this.updatedata=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.neicejixing){
                  $this.testModelStatuas=true
                  $this.testModel=response.data.result.records;
                  $this.allTestModel=response.data.result;
                }else if(data.blockId==interfaces.blockIdArr.wbcode){
                  if(response.data.result.records!=null&&response.data.result.records!=undefined&&response.data.result.records!=""){
                    if(response.data.result.records[0].coverPicture){
                      $this.qqcode=response.data.result.records[0].coverPicture;
                    }
                  }
                  console.log($this.qqcode)
                }else if(data.blockId==interfaces.blockIdArr.vxcode){
                  if(response.data.result.records!=null&&response.data.result.records!=undefined&&response.data.result.records!=""){
                    
                    if(response.data.result.records[0].coverPicture){
                      $this.weixincode=response.data.result.records[0].coverPicture;
                    }
                  }
                }
            })
            .catch(function (error) {
                return error
            });
      },
        //获取内容详情
        getContent(data){
          var $this = this;
          console.log(this)
          var config = {
              method: "post",
              url: `${interfaces.baseUrl}/portal/open/block/getContent`,
              headers:interfaces.postHeaders(),
              data,
            };
            axios(config)
              .then(function (response) {
                  //console.log(response.data);
                  return response
              })
              .catch(function (error) {
                  return error
              });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            return Y+M+D;
        },
        goDetails(id){
          console.log(id);
          this.$router.push({
            name: 'newsdetails',
            query:{
              id:id
            }
          })
        },
        selectFn(){
          this.$router.push({
            name: 'newsSelect',
            query:{
              searchText:this.searchText
            }
          })
        },
        goYearsUpdate(value){
          this.$router.push({
            name: 'yearsUpdate',
            query:{
              value
            }
          })
        },
        //更新数据过滤
        dataFilter(key,value,tarrget){
            return tarrget.filter((item,index)=>{
              return item[key]==value
            })
        },
        //当前所在悬浮
        navSlide(index,item){
          var $this=this
           this.undateIndex=index;
           this.twoUndateIndex=0;
           this.updatePageNum=1
           $this.selectArray=[];
           $this.selectArray.push(item.value)
           if(item.childList&&item.childList.length>0){
             for(let i=0;i<item.childList.length;i++){
              if(item.childList[i].childList&&item.childList[i].childList.length>0){
                for(let j=0;j<item.childList[i].childList.length;j++){
                  $this.selectArray.push(item.childList[i].childList[j].value)
                }
               $this.selectArray.push(item.childList[i].value)
             }
             }
           }
           console.log($this.selectArray)
           this.getUpdateMsg($this.selectArray)
        },
        //当前所在悬浮
        twoNavSlide(index,item){
          var $this=this
           this.twoUndateIndex=index+1;
           this.updatePageNum=1
           $this.selectArray=[];
           console.log(item);
           $this.selectArray.push(item.value)
           if(item.childList&&item.childList.length>0){
             for(let i=0;i<item.childList.length;i++){
               $this.selectArray.push(item.childList[i].value)
             }
           }
          //  $this.selectArray.push(value);
          //  for(let i=0;i<getSelectArray.length;i++){
          //    $this.selectArray.push(getSelectArray[i].value)
          //  }
          //  console.log($this.selectArray)
           this.getUpdateMsg($this.selectArray)
        },
        provFn(){
          var $this=this
          $this.updatePageNum=$this.updatePageNum-1
          if($this.updatePageNum<1){
            $this.updatePageNum=1
          }
          $this.getUpdateMsg($this.selectArray)
        },
         nextFn(allPageNum){
          var $this=this;
          // alert(1)
          $this.updatePageNum=$this.updatePageNum+1
          if($this.updatePageNum>allPageNum){
            $this.updatePageNum=allPageNum
          }
          $this.getUpdateMsg2($this.selectArray)
        },
        //获取更新信息
        getUpdateMsg(valueArray){
          var $this=this;
          var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data:{
            "blockId": interfaces.blockIdArr.newsjixing,
            "pageNum":$this.updatePageNum,
            "pageSize":$this.updatePageSize,
            "searchText":"",
            "filterConditionList":[
              {
                  "type": 4,
                  "code": "CUSTOMER_PORTAL_PRODUCT_NUMBER",
                  "value": valueArray
              }
            ]
          },
            };
    
           axios(config)
            .then(function (response) {
              console.log(response.data.result)
              $this.updateRecordsStatus=true
               $this.updateRecords=response.data.result
               console.log($this.updateRecords)
            })
            .catch(function (error) {
                return error
            });
        },
        getUpdateMsg2(valueArray){
          var $this=this;
          var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data:{
            "blockId": interfaces.blockIdArr.newsjixing,
            "pageNum":$this.updatePageNum,
            "pageSize":$this.updatePageSize,
            "searchText":"",
            "filterConditionList":[
              {
                  "type": 4,
                  "code": "CUSTOMER_PORTAL_PRODUCT_NUMBER",
                  "value": valueArray
              }
            ]
          },
            };
    
           axios(config)
            .then(function (response) {
              console.log(response.data.result)
               $this.updateRecords.records=$this.updateRecords.records.concat(response.data.result.records)
               console.log($this.updateRecords)
            })
            .catch(function (error) {
                return error
            });
        },

        nextTestFn(allPageNum){
          var $this=this;
          // alert(1)
          $this.testPageNum=$this.testPageNum+1
          if($this.testPageNum>allPageNum){
            $this.testPageNum=allPageNum
          }
          $this.getTestMsg()
        },
         getTestMsg(){
          var $this=this;
          var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data:{
            "blockId": interfaces.blockIdArr.neicejixing,
            "pageNum":$this.testPageNum,
            "pageSize":$this.testPageSize,
            "searchText":"",
            "filterConditionList":[
              
            ]
          },
            };
    
           axios(config)
            .then(function (response) {
              console.log(response.data.result)
               $this.testModel=$this.testModel.concat(response.data.result.records)
               console.log($this.testModel)
            })
            .catch(function (error) {
                return error
            });
        },
        
        
  },
};
</script>
<style lang="less" scoped>

body{
	overflow: auto;
	background:#f5f5f6 !important;
}
.main-box{
	width: 100%;
	overflow: hidden;
  position: relative;
  	background:#f5f5f6
}
.main-box img{
	display: block;
	width: 100%;
}
.main-box .product01-box{
	width: 100%;
  height: 3rem;
  margin-top:.96rem;
  background:#f5f5f6;
  overflow: hidden;
  position: relative;
}

.main-box .product01-box .img1{
  display: block;
  width:7.2rem;
  margin: .15rem auto 0 auto;
  height: 3rem;
  border-radius: .1rem;
}
.main-box .product01-box .select-box{
  position:absolute;
  top:.3rem;
  left: .9rem;
   width:5.7rem;
   height: .6rem;
   line-height: .6rem;
   display: flex;
   justify-content: center;
   border: 1px solid #dfe1e5;
   border-radius: .6rem;
   box-sizing: border-box;
   align-items: center;
   overflow: hidden;
   background: #ffffff;
}
.main-box .product01-box .select-box input{
	background: none;
	margin-left: .15rem;
	color: #000000;
	font-size: .24rem;
}
.main-box .product01-box .select-box input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.main-box .product01-box .select-box input::-moz-placeholder { 
    color: #dfe1e5;
}
.main-box .product01-box .select-box input:-ms-input-placeholder {
    color: #dfe1e5;
}
.main-box .product01-box .select-box input:-moz-placeholder {
    color: #dfe1e5;
}
.main-box .product01-box .select-box .left-img{
  width:.28rem;
  height:.28rem;
	// height: 14px;
	display: block;
}
.main-box .product01-box .select-box .left-img img{
  width: 100%;
}
.main-box .product01-box .select-box .right-input{
	width: 4.9rem;
}
.product02-box{
	position: relative;
	width: 100%;
  overflow: hidden;
  padding-bottom: .6rem;
}
.product02-box .product02-content{
	width:7.2rem;
	margin:.14rem auto 0 auto;
	overflow: hidden;
	// padding-bottom:70px;
	// border-bottom: 1px solid #dfe1e5;
}

.product02-box .product02-content .content-box{
   width: 100%;
   overflow: hidden;
   display: flex;
   flex-wrap: wrap !important;
   float: left;
}
.product02-box .product02-content .content-box .product02-slide{
	position: relative;
  width:3.52rem;
  height:4.17rem;
	margin-left:.15rem;
	margin-top:.15rem;
	border-radius:.1rem;
	overflow: hidden;
  background: #ffffff;
  cursor: pointer;
}
.product02-box .product02-content .content-box .product02-slide:nth-of-type(2n+1){
	margin-left: 0;
}
.product02-box .product02-content .content-box .product02-slide:nth-of-type(1){
	margin-top: 0;
}
.product02-box .product02-content .content-box .product02-slide:nth-of-type(2){
	margin-top: 0;
}
.product02-box .product02-content .content-box .product02-slide .top-time{
	position: absolute;
	bottom:.15rem;
	left:.15rem;
	/* width:2rem; */
	// height: 21px;
	// border-radius:21px;
	// background: #2a2930;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size:.24rem;
  color: #d2d2d3;
  z-index: 10;
}
.product02-box .product02-content .content-box .product02-slide .top-time .top-time-logo{
	width:.3rem;
}
.product02-box .product02-content .content-box .product02-slide .top-time .text{
  margin-left:.08rem;
  	font-size:.24rem;
}
.product02-box .product02-content .content-box .product02-slide .top-img{
	display: block;
  width: 100%;
  height:2.6rem;
  overflow: hidden;
}
.product02-box .product02-content .content-box .product02-slide .top-img img{
transition: all 0.6s;
}
.product02-box .product02-content .content-box .product02-slide:hover .top-img img{
  transform: scale(1.06);
}
.product02-box .product02-content .content-box .product02-slide .footer-text{
	width: 3.2rem;
	box-sizing: border-box;
	margin:.2rem auto 0 auto;
	color: #5a596c;
	font-size: .24rem;
  line-height:.4rem;
  height:.85rem;
}
/* 样式3 */
.product03-box{
	position: relative;
  width: 100%;
  padding-bottom: .8rem;
	// height:18.55rem;
	overflow: hidden;
  // margin-top:77px;
  background: #ffffff;
}
// .product03-box-min1{
//   height: 9.8rem;
// }
// .product03-box-min2{
//   height: 13.64rem;
// }
.product03-box .top-tit{
	overflow: hidden;
}
.product03-box .top-tit .p1{
	text-align: center;
	color: #0d0c22;
  font-size: .55rem;
  margin-top: 1rem;
}
.product03-box .top-tit .p2{
	text-align: center;
	color: #999999;
  font-size: .24rem;
  margin-top: .2rem;
}
.product03-box .nav-table{
    width:7.2rem;	
	margin: 0 auto;
	margin-top:.76rem;
	display: flex;
  justify-content: center;
  
}
.product03-box .nav-table .nav-slide .top-hover-box{
	width:1.7rem;
	position: relative;
	border-bottom: 2px solid #ffffff;
	text-align: center;
	font-size:.24rem;
	color:#999999;
	padding-bottom:.2rem;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: .02rem solid #e0e0e0;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img{
	position: relative;
	width:100%;
  height:.5rem;
  
	text-align: center;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img img{
  display: block;
  // width:.66rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	position: absolute;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img .img1{
  display: block;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img .img2{
  display: none;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-img .img1{
  display: none !important;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-img .img2{
  display: block !important;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-txt{
  margin-top: .15rem;
  height: .35rem;
  line-height: .35rem;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-txt{
  color: #0d0c22;
}
// .product03-box .nav-table .nav-slide .top-hover-box .bottom-line{
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	width: 100%;
// 	height: .02rem;
// 	background:#e0e0e0;
// }
.product03-box .nav-table .nav-slide .top-hover-box .bottom-line-active{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: .02rem;
	background:#0d0c22;
	display: none;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .bottom-line-active{
	display: block;
} 
.product03-box .nav-table .nav-slide .two-nav-box{
	position: absolute;
	top:4.54rem;
  left:50%;
  width: 7.2rem;
	transform: translate(-50%,0);
  display: none;
}
.product03-box .nav-table .nav-slide-active .two-nav-box{
	 display: flex;
  justify-content: center;
}
.product03-box .nav-table .nav-slide .two-nav-box .two-slide{
	float: left;
	padding:0 .2rem;
	height:.5rem;
	line-height:.5rem;
	border-radius:.5rem;
	border: 1px solid #242435;
	text-align: center;
	color: #5a596c;
	font-size:.24rem;
	margin-left:.15rem;
}
.product03-box .nav-table .nav-slide .two-nav-box .two-slide-active{
	background: #242435;
	color: #ffffff;
}
.three-nav-box{
	overflow: hidden;
  width:7.2rem;
  margin: 0 auto;
 
	// display: none;
}
.product03-box .nav-table .nav-slide-active .three-nav-box{
  display: block;
  display: flex;
  justify-content: center;
} 
.three-nav-box .product03-slide{
	float: left;
	width:3.52rem;
	position: relative;
	margin-left:.15rem;
  height:3.47rem;
  background: #F5F5F6;
  border-radius: .1rem;
  overflow: hidden;
	margin-top:.15rem;
}
.three-nav-box .product03-slide:nth-of-type(2n+1){
	margin-left: 0;
}
.three-nav-box .product03-slide .footer-text{
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	color: #5a596c;
	font-size: .24rem;
  bottom:.35rem;
  // height: 35px;
  // line-height: 35px;
  background: #F5F5F6;
}
.three-nav-box .three-nav-rel{
	// position: relative;
  width: 100%;
  margin-top: 1.3rem;
  overflow: hidden;
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
justify-content: center;
	// height: 13.62rem;
}
.three-nav-box .three-nav-rel .no-msg-txt{
   margin-top: 50px;
   font-size: 50px;
   text-align: center;
}
.three-nav-box .three-nav-rel .no-msg-txt .no-msg{
  width: 2.2rem;
  margin: 0 auto;
}
.bottom-btn{
 margin-top: .6rem;
 float: left;
 width: 100%;
}
.bottom-btn .bottom-btn-rel{
	position: relative;
	width: 100%;
  height: 100%;
}
.bottom-btn .bottom-btn-rel .see-more{
  display: flex;
  justify-content: center;
  color:#0d0c22;
  font-size: .24rem;
}
.bottom-btn .bottom-btn-rel .see-more span{
  color:#0d0c22;
  font-size: .24rem;
}
.bottom-btn .bottom-btn-rel .see-more .see-more-logo{
  width:.23rem;
  margin-top: .1rem;
}
// .product04-box{
// 	width: 100%;
// 	// height: 400px;
// 	overflow: hidden;
// }
// .product04-box img{
// 	width: 100%;
// }
.main-box .product04-box{
  width:100%;
  height: 3rem;
  background: #ffffff;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.main-box .product04-box img{
  //  height:5.15rem;
   width: 7.2rem;
   margin: 0 auto;
}

.product05-box{
	position: relative;
  width: 100%;
  background: #ffffff;
  padding-bottom:.72rem;
	// margin-top:95px;
}
// .product05-box .big-p{
// 	text-align: center;
// 	color: #0d0c22;
// 	font-size: 50px;
// }
.product05-box .product05-content{
	width: 7.2rem;
	margin: 0 auto;
	overflow: hidden;
}
.product05-box .product05-content .product05-slide{
	float: left;
	margin:.15rem 0 0 .15rem;
	width:3.52rem;
	height:3.47rem;
  position: relative;
    border-radius: 5px;
  overflow: hidden;
    background: #F5F5F6;
}
.product05-box .product05-content .product05-slide:nth-of-type(2n+1){
  margin-left: 0;
}
.product05-box .product05-content .product05-slide .top-img{
	width: 100%;
  height: 100%;
}
.product05-box .product05-content .product05-slide .footer-text{
	position: absolute;
	bottom:.35rem;
	left: 0;
	width: 100%;
	text-align: center;
	color: #5a596c;
  font-size: .24rem;
  // height: 35px;
  // line-height: 35px;
  background: #F5F5F6;
}
.product06-box{
	width:7.2rem;
  margin: 50px auto 0 auto;
  position: relative;
  padding-bottom: 50px;
}
.product06-box .weixincode{
  width:1.77rem;
  height: 1.79rem;
  position: absolute;
  top:2.68rem;
  left:.96rem;
}
.product06-box .qqcode{
  width:1.77rem;
  height: 1.79rem;
  position: absolute;
  top:2.68rem;
  left:4.44rem;
}
.no-msg2{
  width: 2.2rem !important;
  margin: 0 auto;
  margin-top: .6rem;
  padding-bottom: .6rem;
}
.no-msg2 img{
  width: 100%;
}
</style>
